import React from "react";
import img from "../global/images/about.png";
import { whiteLogo } from "../global/GlobalVariables";

const About = ({ aboutRef }) => {
    const videoUrl = "https://youtu.be/ozPXE-BhsbY";
    const title = "Добродојдовте во Yadea Скопје!";
    const description = "Вашата доверлива дестинација за високо квалитетни електрични скутери";

    const cards = [
        { header: "Сертифициран квалитет", body: "Нашите скутери се произведени според највисоките стандарди за сигурност и издржливост", icon: "fa-solid fa-medal" },
        { header: "Еко-пријателски", body: "Yadea електричните скутери се дизајнирани да го намалат вашиот еколошки отпечаток", icon: "fa-solid fa-leaf" },
        { header: "Поддршка и сервис", body: "Нудиме целосна поддршка и сопствен сервис за сите наши модели", icon: "fa-solid fa-headset" },
        { header: "Стил и удобност", body: "Нашите дизајни ги комбинираат стилот и удобноста за максимално уживање при возење", icon: "fa-solid fa-gem" }
    ];

    return (
        <section className="bg-[#eee] min-h-screen flex flex-col" ref={aboutRef}>
            <div className="max-w-4xl mx-auto py-14 flex-grow flex flex-col justify-center">
                <div className="mb-6">
                    <h2 className="text-3xl md:text-5xl font-semibold mb-4">{title}</h2>
                    <p className="text-gray-700 md:text-xl">{description}</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 px-4 md:px-0 max-w-4xl mx-auto">
                    {cards.map((card, index) => (
                        <div key={index} className="bg-white rounded-lg shadow-lg text-center items-center">
                            <div className="text-lg font-semibold bg-[#ff5e00] rounded-lg">
                                <span className="flex p-3 justify-center items-center w-full text-white">
                                    <i className={`${card.icon} mr-2`}></i>{card.header}
                                </span>
                            </div>
                            <div className="text-gray-600 p-3 flex items-center h-20 sm:h-28 text-base sm:text-lg">
                                {card.body}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 mt-10">
                    <div className="w-full">
                        <img src={img} alt="about" />
                    </div>
                    {/* Embed Video with Picture Frame Style */}
                    <div className="w-full px-2 md:px-0 flex justify-center grow">
                        <div
                            onClick={() => window.open(videoUrl, "_blank")}
                            className="relative cursor-pointer group border-4 border-[#ff5e00] rounded-lg"
                        >
                            <img
                                src="https://img.youtube.com/vi/mNh7cl7G8NA/hqdefault.jpg"
                                alt="Yadea Skopje Video Thumbnail"
                                className="w-full rounded-lg shadow-lg"
                            />
                            <div className="absolute inset-0 flex flex-col text-white items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300">
                                <span className="text-lg pb-4">Дознајте повеќе за</span>
                                <img src={whiteLogo} alt="Yadea Corporate Video" />
                                <i className="fa fa-circle-play text-6xl text-[#ff5e00] pt-4"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
