import React, { useState, useEffect } from "react";
import { models } from "./models_data";

const ModelSidebar = ({ index, onClose }) => {
    const [model, setModel] = useState(null)
    const [openSpec, setOpenSpec] = useState(0)

    useEffect(() => {
        setModel(models[index]);
    }, [index]);

    const toggleSidebar = () => {
        setModel(null)
        setTimeout(() => onClose(), 300)
    }

    const toggleSpec = (index) => setOpenSpec(openSpec === index ? null : index)

    return (
        <div className={`absolute z-40 top-12 left-0 mt-0.5 background-nav text-white h-screen w-96 p-2 overflow-y-auto transform transition-transform duration-300 ${
            model ? "translate-x-0" : "-translate-x-full"
          }`}>
            <div className="flex w-full justify-between pb-4 pt-2 border-b px-4">
                <h2 className="text-center text-2xl sm:text-3xl font-semibold ">Модел: {model?.title}</h2>
                <button
                    className="flex items-center hover:text-[#FF5E00]"
                    onClick={toggleSidebar}>
                    <i className="fa-regular fa-circle-xmark text-xl"></i>
                </button>
            </div>
            <div className="flex flex-col justify-center gap-8 p-4">
                {/* Gallery/Carousel of images */}
                {/* <img key={index} src={model.gallery[0]} alt={`${model.title} - ${index + 1}`} className="gallery-image" /> */}
                <div className="flex flex-col w-full space-y-5">
                    {model?.specs.map((spec, index) =>
                        <div key={index} className="rounded-md bg-[#ff5e00] border border-[#ff5e00]">
                            <div
                                className="flex justify-between items-center cursor-pointer p-4 font-medium"
                                onClick={() => toggleSpec(index)}
                            >
                                <div className="flex flex-row items-center gap-2">
                                    <i className={`${spec.icon} text-xl`} />
                                    <h3 className="text-base sm:text-xl text-left">{spec.type}</h3>
                                </div>
                                <span className='w-20 text-right'>{openSpec === index ? (
                                    <i className='fa fa-chevron-up' />
                                ) : (
                                    <i className='fa fa-chevron-down' />
                                )}</span>
                            </div>
                            <div
                                className={`overflow-hidden transition-all duration-300 bg-white ease-in-out ${openSpec === index ? 'max-h-[300px]' : 'max-h-0'
                                    }`}
                            >
                                <div className="flex flex-col rounded-md">
                                    {spec.details.map((detail, index) =>
                                        <div key={index}>
                                            <div className="text-gray-700 flex flex-row justify-between py-0.5 px-1.5 md:px-5 border-t border-[#ff5e00]">
                                                <span className="text-start">{detail.mk}</span>
                                                <b className="text-end">{detail.value}</b>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ModelSidebar;