import React, { useState, useEffect, useRef } from "react";
import useWindowSize from "../global/hooks/useWindowSize"
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import Loader from "../loader/Loader";
import Hero from "../hero/Hero";
import Specs from "../specs/Specs";
import "../../App.css"
import About from "../about/About";
import FAQ from "../faq/FAQ";
import ModelSidebar from "../models/Sidebar";

const Landing = () => {
    const { width } = useWindowSize()
    // Loaders
    const [isLoadingDone, setIsLoadingDone] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isVisible, setIsVisible] = useState(false);

    const [selectedModel, setSelectedModel] = useState(null);

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

    const toggleVisibility = () => setIsVisible(window.scrollY > 150)

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // enables smooth scroll
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility); // cleanup the event listener
        };
    }, []);

    const loaderDone = () => setIsLoadingDone(true);

    const handleImageLoad = () => setIsImageLoaded(true);

    // ref scrolls
    const specsRef = useRef(null)
    const aboutRef = useRef(null)
    const faqRef = useRef(null)
    const contactRef = useRef(null)

    const scrollToSection = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });

    const scrollToSpecs = () => scrollToSection(specsRef);
    const scrollToAbout = () => scrollToSection(aboutRef);
    const scrollToFaq = () => scrollToSection(faqRef);
    const scrollToContact = () => scrollToSection(contactRef);

    const selectModel = (index) => {
        setSelectedModel(index);
    };

    const handleCloseModal = () => {
        setSelectedModel(null);
    };

    return (
        <section className="bg-white min-h-screen relative">
            {!isLoadingDone && !isImageLoaded
                ? <Loader loaderDone={loaderDone} height={'h-screen'} />
                : (
                    <div>
                        <Navbar
                            toggleSidebar={toggleSidebar}
                            isSidebarOpen={isSidebarOpen}
                            loaded={isLoadingDone && isImageLoaded}
                            scrollToSpecs={scrollToSpecs}
                            scrollToAbout={scrollToAbout}
                            scrollToFaq={scrollToFaq}
                            scrollToContact={scrollToContact}
                            selectModel={selectModel}
                            backToTop={backToTop}
                        />

                        <main className={`transition-up ${isLoadingDone && isImageLoaded ? "show" : ""}`}>
                            {/* Landing banner */}
                            <Hero handleImageLoad={handleImageLoad} selectModel={selectModel} selectedModel={selectedModel} />
                            {/* Models list */}
                            {/* {!isImageLoaded ? null : <Models modelsRef={modelsRef}/>} */}

                            <Specs specsRef={specsRef} />

                            <About aboutRef={aboutRef} />

                            <FAQ faqRef={faqRef} />

                            <Footer contactRef={contactRef} />


                            {/* For mobile view */}
                            {isSidebarOpen && width <= 640 && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={toggleSidebar}></div>
                            )}

                            {selectedModel !== null && (
                                <section>
                                    {/* <div className="fixed inset-0 bg-black bg-opacity-50 z-10" onClick={handleCloseModal}></div> */}
                                    <ModelSidebar index={selectedModel} onClose={handleCloseModal} />
                                </section>

                            )}
                        </main>
                        {/* Back to top button */}
                        <button
                            id="backToTopBtn"
                            className={`fixed z-20 bottom-4 right-4 h-12 w-12 rounded-full border-2 border-[#FF5E00] bg-transparent text-[#FF5E00] hover:bg-[#FF5E00] hover:text-white cursor-pointer transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                            onClick={backToTop}
                        >
                            <i className="fa-solid fa-arrow-up"></i>
                        </button>
                    </div>
                )}

        </section>
    )
}

export default Landing;