import e8s from "../global/images/e8s.webp"
import gt20 from "../global/images/gt20.webp"
import oris from "../global/images/oris.webp"
import sparkPro from "../global/images/spark-pro.webp"
import keeness from "../global/images/keeness.webp"

export const models = [
    {
        title: "Keeness",
        gallery: [keeness],
        isPromoPrice: false,
        price: "4,980",
        available: true,
        attributes: ["Брзина", "Перформанс", "Моќност"],
        specs: [
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "Mid-drive", mk: "Мотор" },
                    { value: "5500 W", mk: "Моќност" },
                    { value: "7000 W", mk: "Највисока моќност" },
                    { value: "300 Nm", mk: "Максимален вртежен момент" },
                    { value: "100 km/h", mk: "Максимална брзина" },
                ]
            },
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "780мм", mk: "Ширина" },
                    { value: "2010мм", mk: "Должина" },
                    { value: "1100мм", mk: "Висина" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Литиумска", mk: "Вид на батерија" },
                    { value: "32Ah", mk: "Капацитет на батерија" },
                    { value: "72V", mk: "Напон на батерија" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "5 инчи со висока резолуција", mk: "Дисплеј" },
                    { value: "Обратно поставена", mk: "Предна вилушка" },
                    { value: "Средно монтиран", mk: "Амортизер" },
                ]
            }
        ]
    },
    { 
        title: "Owin",
        gallery: [oris],
        isPromoPrice: false,
        price: "1,390",
        available: true,
        attributes: ["Ретро", "Комфорт", "Стил"],
        specs: [
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "665мм", mk: "Ширина" },
                    { value: "1814мм", mk: "Должина" },
                    { value: "1135мм", mk: "Висина" },
                    { value: "780мм", mk: "Висина на седиште" },
                    { value: "640мм", mk: "Должина на седиште" },
                ]
            },
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "Вграден во тркало", mk: "Мотор" },
                    { value: "1500 W", mk: "Моќност" },
                    { value: "2400 W", mk: "Највисока моќност" },
                    { value: "125 Nm", mk: "Максимален вртежен момент" },
                    { value: "45 km/h", mk: "Максимална брзина" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "TTFAR графенска", mk: "Вид на батерија" },
                    { value: "25Ah", mk: "Капацитет на батерија" },
                    { value: "72V", mk: "Напон на батерија" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "Интеракција за отклучување", mk: "Yadea app" },
                    { value: "15 секунди по паркирање", mk: "Анти-кражба мод" },
                    { value: "Еко, Спорт и др.", mk: "Модови за возење" },
                ]
            }
        ]
    },
    {
        title: "E8S",
        gallery: [e8s],
        isPromoPrice: false,
        price: "1,390",
        available: true,
        attributes: ["Моќен", "Елегантен", "Паметен"],
        specs: [
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "TTFAR", mk: "Мотор" },
                    { value: "2000 W", mk: "Моќност" },
                    { value: "3000 W", mk: "Највисока моќност" },
                    { value: "149 Nm", mk: "Максимален вртежен момент" },
                    { value: "60 km/h", mk: "Максимална брзина" },
                ]
            },
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "760мм", mk: "Ширина" },
                    { value: "1870мм", mk: "Должина" },
                    { value: "1155мм", mk: "Висина" },
                    { value: "830мм", mk: "Висина на седиште" },
                    { value: "650мм", mk: "Должина на седиште" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Графенска, 3-та ген.", mk: "Вид на батерија" },
                    { value: "38Ah", mk: "Капацитет на батерија" },
                    { value: "72V", mk: "Напон на батерија" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "ЛЕД", mk: "Светла" },
                ]
            }
        ]
    },
    {
        title: "Spark Pro",
        gallery: [sparkPro],
        isPromoPrice: false,
        price: "1,250",
        available: true,
        attributes: ["Агилен", "Прифатлив", "Ефикасен"],
        specs: [
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "2000 W", mk: "Моќност" },
                    { value: "4000 W", mk: "Највисока моќност" },
                    { value: "56 km/h", mk: "Максимална брзина" },
                    { value: "90/90-12", mk: "Предна/Задна гума" },
                    { value: "Диск/добош", mk: "Предна/Задна сопирање" },
                ]
            },
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "710мм", mk: "Ширина" },
                    { value: "1910мм", mk: "Должина" },
                    { value: "1075мм", mk: "Висина" },
                    { value: "1325мм", mk: "Растојание на тркала" },
                    { value: "176кг", mk: "Максимална тежина" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Графенска, 72v26ah", mk: "Вид на батерија" },
                    { value: "70-100км", mk: "Опсег на батерија" },
                    { value: "2h брзо / 10h полнач", mk: "Време на полнење" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "ЛЕД", mk: "Светла" },
                    { value: "KEYLESS GO", mk: "Старт" },
                ]
            }
        ]
    },
    {
        title: "GT20",
        gallery: [gt20],
        isPromoPrice: false,
        price: "1,280",
        available: true,
        attributes: ["Квалитет", "Перформанс", "Спортски"],
        specs: [
            {
                type: "Мотор", icon: "fa-solid fa-gear", details: [
                    { value: "1500 W", mk: "Моќност" },
                    { value: "2700 W", mk: "Највисока моќност" },
                    { value: "45 km/h", mk: "Максимална брзина" },
                    { value: "135 Nm", mk: "Максимален вртежен момент" },
                ]
            },
            {
                type: "Димензии", icon: "fa-solid fa-sliders", details: [
                    { value: "690мм", mk: "Ширина" },
                    { value: "1845мм", mk: "Должина" },
                    { value: "1120-1300мм", mk: "Висина" },
                    { value: "1910мм", mk: "Должина на педали" },
                    { value: "1075мм", mk: "Висина на седиште" },
                    { value: "140мм", mk: "Растојание од под" },
                ]
            },
            {
                type: "Батерија", icon: "fa-solid fa-battery", details: [
                    { value: "Графенска олово-киселинска", mk: "Вид на батерија" },
                    { value: "72V 25Ah", mk: "Капацитет на батерија" },
                    { value: "700", mk: "Циклус на батерија" },
                    { value: "8h", mk: "Време на полнење" },
                ]
            },
            {
                type: "Останато", icon: "fa-solid fa-gauge-high", details: [
                    { value: "28L", mk: "Складиште" },
                ]
            }
        ]
    }
]