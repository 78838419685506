import React, { useState } from 'react';
import { faqData } from '../global/GlobalVariables';

const FAQ = ({ faqRef }) => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <section
      className="bg-white min-h-screen flex flex-col"
      ref={faqRef}
    >
      <div className="max-w-7xl mx-auto py-10 flex-grow flex flex-col justify-center px-2">
        <h2 className="text-3xl md:text-5xl font-semibold mb-10">Често поставувани прашања</h2>
        <div className="space-y-6">
          {faqData.map((faq, index) => (
            <div key={index} className="border-2 shadow-md rounded-md bg-[#FF5E00]">
              <div
                className="flex justify-between items-center cursor-pointer p-4 font-medium text-white"
                onClick={() => toggleQuestion(index)}
              >
                <h3 className="text-base sm:text-xl text-left">{faq.question}</h3>
                <span className="w-20 text-right">
                  {openQuestion === index ? (
                    <i className="fa fa-chevron-up" />
                  ) : (
                    <i className="fa fa-chevron-down" />
                  )}
                </span>
              </div>
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openQuestion === index ? 'max-h-[300px]' : 'max-h-0'
                }`}
              >
                <p className="text-gray-700 bg-white text-left p-4">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
