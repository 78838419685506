import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { models } from "../models/models_data";
import useWindowSize from "../global/hooks/useWindowSize";
import "./Hero.css";
import CustomDot from "./CustomDot";

const Hero = ({ handleImageLoad, modelsRef, selectModel, selectedModel }) => {
    const { width } = useWindowSize();

    const responsive = {
        desktop: {
            breakpoint: { max: 5000, min: 1024 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
        },
    };

    const handleViewMoreClick = (index) => {
        selectModel(index);
    };

    const scrollToBottom = () => window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
    });

    const setTextPostition = (index) => {
        if (index === 0) return "text-start";
        if (index === 1) return "text-center"
        return "text-end"
    }

    return (
        <section className="h-screen max-w-full overflow-hidden">
            <Carousel
                additionalTransfrom={0}
                responsive={responsive}
                infinite={true}
                showDots={true}
                customDot={<CustomDot />}
                partialVisible={true}
                arrows={width > 600 && selectedModel === null}
                centerMode={false}
                className="carousel"
                autoPlay={true}
                autoPlaySpeed={5000}
            >
                {models.map((model, index) => (
                    <div className="carousel-slide" key={model.title} ref={modelsRef}>
                        <div className="content-wrapper relative color-animation ">
                            <h2 className="text-center text-7xl font-semibold mb-2">{model.title}</h2>
                            <hr className="my-2 bg-black max-w-2xl mx-auto w-full"></hr>
                            {!!model.attributes?.length && (
                                <div className="grid grid-cols-3 justify-center items-center max-w-xl w-full">
                                    {model.attributes.map((att, index) => (
                                        <span key={index} className={`text-xl sm:text-2xl ${setTextPostition(index)} `}>
                                            {att}
                                        </span>
                                    ))}
                                </div>
                            )}
                            <hr className="my-2 bg-black max-w-2xl mx-auto w-full"></hr>
                            {/* <hr className="mt-2 mb-5 bg-black w-full"></hr> */}
                            <div className="carousel-image-container">
                                <img
                                    src={model.gallery[0]}
                                    alt="scooter"
                                    onLoad={handleImageLoad}
                                    className="carousel-image"
                                    onClick={() => handleViewMoreClick(index)}
                                />
                                {!model.available &&
                                    <div className="coming-soon-overlay hover:more-red">
                                        <span className="coming-soon-text px-2">Наскоро!</span>
                                    </div>
                                }
                            </div>

                            {/* {model.available ? (
                                !!(width > 768) &&
                                <button className="view-more-overlay gap-2 px-4" onClick={() => handleViewMoreClick(index)}>
                                    <i className="fa fa-eye eye-icon text-5xl"></i>
                                    <span className="text-3xl">Види повеќе</span>
                                </button>
                            ) : (
                                
                            )} */}

                            {!!model.available && (
                                <div className="flex flex-col w-full">
                                    <hr className="bg-black max-w-2xl mx-auto w-full"></hr>
                                    <div className="flex flex-row w-full gap-10 justify-center mt-8 px-1">
                                        <div className="relative overflow-hidden w-52 sm:w-96">
                                            {!!model.isPromoPrice && (
                                                <>
                                                    <span className="diagonal-ribbon-top-left">Промо</span>
                                                    <span className="diagonal-ribbon-bottom-right">Цена!</span>
                                                </>
                                            )}
                                            <div className="bg-[#fff5eb] border border-[#ff5e00] rounded-md shadow-lg font-bold p-2">
                                                {model.price ? (
                                                    <span className="text-3xl sm:text-5xl text-[#ff5e00]">
                                                        &#8364;{model.price}
                                                    </span>
                                                ) : (
                                                    <div onClick={scrollToBottom} className="cursor-pointer">
                                                        <i className="fa fa-phone text-xl sm:text-3xl mr-2"></i>
                                                        <span className="text-xl sm:text-3xl">Kонтакт за цена</span>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                        <button
                                            className="hover:text-[#ff5e00] hover:bg-[#fff5eb] hover:border-[#ff5e00] color-animation border border-black w-52 sm:w-96 p-2 rounded-md shadow-md"
                                            onClick={() => handleViewMoreClick(index)}
                                        >
                                            <i className="fa fa-cogs text-xl sm:text-3xl mr-1.5"></i>
                                            <span className="text-base sm:text-3xl">Спецификации</span>
                                        </button>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </Carousel>
        </section>
    );
};

export default Hero;
